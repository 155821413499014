.root {
  grid-template-areas:
    "icon heading"
    "icon paragraph";
}

.iconBox > * {
  max-height: 100%;
  max-width: 100%;
}
